import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServiceFilters } from '../service-models/service-filters.model';
import { FHService, Page } from 'npx-family-happy-common';
import { ServiceOverview } from 'src/app/shared/models/service-overview.modet';
import { BehaviorSubject } from 'rxjs';

export const DEFAULT_SERVICES_FILTERS: ServiceFilters = {
  searchKey: null,
  businessLine: null,
  customerType: null,
  disabled: null
};

@Injectable({
  providedIn: 'root',
})
export class ServiceServices {

  private baseUrl = `${environment.baseURL}/services`;
  filters = new BehaviorSubject<ServiceFilters>(DEFAULT_SERVICES_FILTERS)

  constructor(private http: HttpClient) { }

  getAllServices(
    page: number,
    pageSize = 20,
    filters = DEFAULT_SERVICES_FILTERS
  ) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');
    return this.http.get<Page<FHService>>(`${this.baseUrl}/list?${params}`);
  }

  getServiceByID(id: number) {
    return this.http.get<FHService>(`${this.baseUrl}/?id=${id}`)
  }
  deleteServiceById(id: number) {
    return this.http.delete<FHService>(`${this.baseUrl}/${id}`);
  }
  saveService(service: FHService) {
    return this.http.post<FHService>(`${this.baseUrl}/`, service)
  }
  //Convertitore Query - FA
  //https://test.request.familyhappy.xyz/v1/services/list?businessLine=BABYSITTER&customerType=B2B&pageNumber=0&pageSize=20
  convertFilterObjectToQueryParam(filters: ServiceFilters) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (entry[1] !== null && entry[1] !== '') {
        queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`;
      }
    });
    return queryString;
  }

  setFilters(filters:any){
    this.filters.next(filters)
  }

  getFilters(){
    return this.filters.asObservable()
  }

  getServiceOverview(serviceID: number, province: string) {
    return this.http.get<ServiceOverview>(`${this.baseUrl}/${serviceID}/summary?province=${province}`);
  }
}
