// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseURL = 'https://test.api.familyhappy.xyz/v1'

export const environment = {
  production: false,
  baseURL,
  firebaseConfig: { //noprod
    apiKey: "AIzaSyAMZJjnDsr7szkJGeJShMljVYRLtCAnov0",
    authDomain: "family-happy-bs-test.firebaseapp.com",
    databaseURL: "https://family-happy-bs-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "family-happy-bs-test",
    storageBucket: "family-happy-bs-test.appspot.com",
    messagingSenderId: "402734596258",
    appId: "1:402734596258:web:375c7f8be67952bb89d7fb",
    measurementId: "G-DYL8EZ3QHF",
  },
  mailServiceURL: `${baseURL.slice(0, baseURL.length - 3)}`,
  requestServiceURL:`${baseURL}/requests`,
  formBaseURL: 'https://babysitter-familyhappy-test.firebaseapp.com',
  cloudFunctionsBaseURL: 'https://europe-west3-family-happy-bs-test.cloudfunctions.net',
  googleMapsAPIKey: 'AIzaSyDj_xYGv-KHPfFiEP2FpLRRW3UoUCxQRUc',
  annunciKey: 'sk_test_annunci_vkDzLS1Mf24F03mHJ0lfDYv9H89NP4D8jPVzRHFJoqZYRFsSCJOVGEPO1juw95uM4NHedJ5FuIqB71yMUHoKQzg2pm34mJB3viSG83es7qPsAQoT0j4SOSCCNDkP2MyjI9eCfkHK5vAUjwQSbAYPdVtM7GR8DuU4MTyQiWzX9EpyjtJfe1DIo1Pi3GQJQK39poaJELEngxYPyfX1kukdZ1dLUyzq1fUYWBVCveqvLYHkdNnqA9k9pwc7viAbBUJOY9jj7TiLKHaS9DEPrVfi29GOX1zLZbOeo8R9ABHMvfzrQPt5UdRsxxALHzrNbUzfxo2QzWXkTyX7YDa3tDEDBfFBZAscVZeUHtFU8FDew6vQv9Yuh14embge09SZjBZGBh3bxfC6i5C229hZKouJ6CYFvEq8olRdkEzVkRpd87KDRkB0hYmwP05tr2RSvIB6JTOeKcnQawIZ1eS6Q9pbV8tM34gkH0FzSm3uRWXNgEoIXu7P',
  bachecaURL: 'https://babysitter-familyhappy-test.web.app/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
