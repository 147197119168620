import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { NurseState } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { RouterStateManagerService } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { Nurse, CertificateService, ModalService, ProvinceFilterComponent, VirtualScrollComponent } from 'npx-family-happy-common';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { NurseService } from '../nurse-service/nurse-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription, catchError, combineLatest, merge, of, takeUntil, tap, throwError, withLatestFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from 'src/app/requests-handling/request-service/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { Loader } from '@googlemaps/js-api-loader';
import html2canvas from 'html2canvas';
import { CaregiverEvaluation, DEFAULT_CAREGIVER_EVALUATION } from 'src/app/shared/models/caregiver-evaluation.model';
import { CaregiverEvaluationRecord } from 'src/app/shared/models/caregiver-evaluation-record.model';
import { EvaluationService } from 'src/app/shared/services/evaluation-service/evaluation.service';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { CaregiverEvaluationComponent } from 'src/app/shared/components/caregiver-evaluation/caregiver-evaluation.component';
import { CaregiverEngagementComponent } from 'src/app/shared/components/caregiver-engagement/caregiver-engagement.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { CvNurseTemplateComponent } from '../cv-nurse-template/cv-nurse-template.component';
import { DEFAULT_CAREGIVER_ENGAGEMENT } from 'src/app/shared/models/caregiver-engagement.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast-service/toast.service';
import { FormControlPipe } from "../../../shared/pipes/form-control.pipe";

@Component({
  selector: 'app-nurse-detail',
  templateUrl: './nurse-detail.component.html',
  styleUrls: ['./nurse-detail.component.scss'],
  standalone:true,
  imports: [CommonModule, ReactiveFormsModule, ProvinceFilterComponent, CaregiverEvaluationComponent, CaregiverEngagementComponent,
    AlertComponent, ImageCropperComponent, CvNurseTemplateComponent, FormControlPipe, VirtualScrollComponent]
})
export class NurseDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  isNew = true;
  hasPayed!: boolean;
  addressSelected: boolean = false;
  showAlert = false;
  showModal = false;
  chosenPhoto!: string;
  finalPhoto!: string;
  step = 0;
  form!: UntypedFormGroup;
  nurse!: Nurse;
  nurseID!: string;
  generatePages: boolean = false;
  cvPages: number = 1;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];
  checkNotRated = true;
  private nextTab = -1;
  private goBackRequested = false
  private nurseState!: NurseState;
  private unsubscribe = new Subject<void>();
  private caregiverRating: CaregiverEvaluation | undefined;
  private currentUser: string = '';
  private oldAddress!: string | undefined

    //default option for NEW NURSE
    defaultRating = DEFAULT_CAREGIVER_EVALUATION
    defaultEngagement = DEFAULT_CAREGIVER_ENGAGEMENT

  constructor(private certificateService: CertificateService, private caregiverEvaluationService: EvaluationService,
    private nurseService: NurseService, private stateManager: RouterStateManagerService,
    private sanitizer: DomSanitizer, private modalService: ModalService, private requestService: RequestService, private router: Router,
    private activeRoute: ActivatedRoute, private datePipe: DatePipe, private userStorage: UserStorageService, private googleMapsService: GoogleMapsService,
    private http:HttpClient, private toastService: ToastService) {
    this.finalPhoto = '';
  }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.currentUser = user?.name ?? '';
    })
    this.form = new UntypedFormGroup({
      info: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        surname: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        phone: new UntypedFormControl(null, Validators.required),
        address: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        zone: new UntypedFormControl(''),
        cap: new UntypedFormControl('', [Validators.pattern(new RegExp('^\\d+$'))]),
        age: new UntypedFormControl(0),
        birthDate: new UntypedFormControl(''),
        provincia: new UntypedFormControl('TO'),
        fiscalcode: new UntypedFormControl('', [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
        iban: new UntypedFormControl(''),
        resume: new UntypedFormControl(''),
        resumeHidden: new UntypedFormControl(''),
        video: new UntypedFormControl(''),
        notes: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        photo: new UntypedFormControl(''),
        subscription: new UntypedFormControl(''),
        manualSubscription: new UntypedFormControl(''),
        expDate: new UntypedFormControl(''),
        expDateManual: new UntypedFormControl(''),
        stopWhatsapp: new UntypedFormControl(false),
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null)
      }),
      exp: new UntypedFormGroup({
        total: new UntypedFormControl(0),
        cluster1: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            assistance: new UntypedFormControl(false),
            care: new UntypedFormControl(false),
            drugs: new UntypedFormControl(false),
            cooking: new UntypedFormControl(false),
            housing: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          help: new UntypedFormControl('')
        }),
        cluster2: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            assistance: new UntypedFormControl(false),
            care: new UntypedFormControl(false),
            drugs: new UntypedFormControl(false),
            cooking: new UntypedFormControl(false),
            housing: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          help: new UntypedFormControl('')
        }),
      }),
      lang: new UntypedFormGroup({
        title: new UntypedFormControl(''),
        graduationType: new UntypedFormControl(''),
        specialities: new UntypedFormGroup({
          injections: new UntypedFormControl(false),
          dressings: new UntypedFormControl(false),
          glycemia: new UntypedFormControl(false),
          technology: new UntypedFormControl(false),
        }),
        english: new UntypedFormControl(''),
        french: new UntypedFormControl(''),
        italian: new UntypedFormControl(''),
        german: new UntypedFormControl(''),
        spanish: new UntypedFormControl(''),
        other1: new UntypedFormControl(''),
        other2: new UntypedFormControl(''),
        certificates: new UntypedFormArray([])
      }),
      reference: new UntypedFormGroup({
        f_surname: new UntypedFormControl(''),
        f_second_surname: new UntypedFormControl('')
      }),
      avail: new UntypedFormGroup({
        walking: new UntypedFormControl(false),
        taxiNoCar: new UntypedFormControl(false),
        taxi: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        dayShift: new UntypedFormControl(false),
        nightShift: new UntypedFormControl(false),
        changing: new UntypedFormControl(false),
        hospital: new UntypedFormControl(false),
        smoker: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        license: new UntypedFormControl(false),
        car: new UntypedFormControl(false),
        days: new UntypedFormGroup({
          sun: new UntypedFormControl(false),
          mon: new UntypedFormControl(false),
          tue: new UntypedFormControl(false),
          wed: new UntypedFormControl(false),
          thu: new UntypedFormControl(false),
          fri: new UntypedFormControl(false),
          sat: new UntypedFormControl(false),
        }),
        hours: new UntypedFormGroup({
          mor: new UntypedFormControl(false),
          aft: new UntypedFormControl(false),
          eve: new UntypedFormControl(false),
        }),
        contract: new UntypedFormGroup({
          occ: new UntypedFormControl(false),
          part: new UntypedFormControl(false),
          full: new UntypedFormControl(false),
        }),
        minWage: new UntypedFormControl(null),
        maxWage: new UntypedFormControl(null),
      })
    });
    this.activeRoute.params.subscribe(param => {
      this.nurseID = param.id;
    })
    try {
      this.stateManager.getNurseState().pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
        this.nurseState = state;
        this.nurseState.id = this.nurseID;
        this.nurseState.route = 'nurse-detail/' + this.nurseID;
        this.oldAddress = state.nurse?.info.address //GET OLD ADDRESS FROM BE (TO COMPARE WITH ADDRESS ON FORM)
        if (this.nurseState.step >= 0) {
          this.step = this.nurseState.step;
        }
        if (this.nurseState.nurse) {
          this.isNew = false;
          this.setNurse(this.nurseState.nurse);
          this.formInitialize(this.nurseState.nurse);
        } else if (!this.nurseID.match('null')) {
          const id = this.nurseID;
          if (id && id !== 'new') {
            this.isNew = false;
            this.nurseService.getSingleNurse(id).pipe(takeUntil(this.unsubscribe)).subscribe((nurses) => {
              let nurse = nurses[0];
              if (nurse) {
                this.setNurse(nurse);
                this.formInitialize(this.nurse);
              }
            });
          }
        }
      });
    } catch (err) {
      this.modalService.showErrorMessage('Errore nel recupero delle informazioni legate alla badante.');
    }

    this.form.get('lang.title')?.valueChanges.subscribe(value => {
      if (value === 'Laurea') {
        this.form.get('lang.graduationType')?.enable({ emitEvent: false });
      } else {
        this.form.get('lang.graduationType')?.disable({ emitEvent: false });
      }
    });

    /* Reset exp clusters when value is 0 */
    /* Listen to every cluster's expYears value changes and then compute the total exp according to the formula */
    combineLatest([
      this.form.get('exp.cluster1.expYears')?.valueChanges,
      this.form.get('exp.cluster2.expYears')?.valueChanges
    ]).pipe(takeUntil(this.unsubscribe)).subscribe((value: any[]) => {
      const first = value ? value[0] : 0;
      const second = value ? value[1] : 0;
      const expSum = first + second;
      const age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(first, second, Math.min(expSum, (age - 16))), { emitEvent: false });
      if (first === 0) {
        this.form.get('exp.cluster1')?.reset(undefined, { emitEvent: false });
        this.form.get('exp.cluster1.expYears')?.setValue(0, { emitEvent: false });
      }
      if (second === 0) {
        this.form.get('exp.cluster2')?.reset(undefined, { emitEvent: false });
        this.form.get('exp.cluster2.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster1.expYears')?.setValue(0);
    this.form.get('exp.cluster2.expYears')?.setValue(0);
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;

    if (inputAddress) {
      inputAddress.addEventListener('change', () => {
        inputAddress.value = '';
        this.form.get('info.address')?.setValue('', { emitEvent: false });
      });
      const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {

        const autocomplete = new google.maps.places.Autocomplete(inputAddress);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          let formAddress = this.form.get('info.address');

          formAddress?.setValue(place.formatted_address);

          // Compile other address fields according to place object
          place.address_components?.forEach((component: any) => {
            if (component.types.includes('locality')) {
              this.form.get('info.city')?.setValue(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              this.form.get('info.cap')?.setValue(component.long_name);
            }
          });
        })
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stateManager.setNurseState(this.nurseState, false);
    this.processFormData(this.form.value).then((res) => {
      this.setNurse(res)
    })
  }

    //GET AVAIL WAGES
    get avail() {
      return this.form.get('avail') as FormGroup;
    }
    get minWage() {
      return this.avail.controls['minWage'] as FormControl;
    }
    get maxWage() {
      return this.avail.controls['maxWage'] as FormControl;
    }

  uploadFile(_event: any, field: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento CV in corso...');
      const file = <File>_event.target.files[0];
      const path = field + '/' + field + this.form.get('info.email')?.value + '.pdf';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (field === 'info.resume') {
            this.nurse.info.resume = downloadURL;
          } else {
            this.nurse.info.resumeHidden = downloadURL;
          }
          this.form.get(field)?.setValue(downloadURL);
          if (this.isNew) {
            this.createNurse();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateNurse();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel caricamento del CV. Si prega di riprovare');
    }
  }

  uploadImage(image: string, id: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento foto in corso...');
      const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
      const path = 'pictures/' + id + '.png';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.form.get('info.photo')?.setValue(downloadURL);
          let body = this.nurse.info;
          body.photo = downloadURL;
          this.nurse.info = body;
          if (this.isNew) {
            this.createNurse();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateNurse();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel corso del caricamento della foto. Si prega di riprovare.');
    }
    this.showModal = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async generateCV(showAlert: boolean) {
    if (this.form.invalid) {
      return
    }
    if (showAlert) {
      this.modalService.showLoadingWithMessage('Generazione CV Family Happy in corso...');
    }
    try {
      this.generatePages = true;
      setTimeout(() => {
        this.htmlToCanvas(showAlert)
      }, 1000);
    } catch (err: any) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nella generazione del CV. Potrebbe essere legato alla foto caricata. Si prega di scaricarla, caricarla nuovamente e riprovare.');
    }
  }

  goBack() {
    if (this.form.dirty) {
      this.showAlert = true;
      this.goBackRequested = true;
    } else {
      this.nurseState.route = 'nurse-list';
      this.nurseState.nurse = undefined;
      this.nurseState.step = 0;
      this.nurseState.id = 'null';
      this.ngOnDestroy();
      this.stateManager.setNurseState(this.nurseState);
    }
  }

  formInitialize(nurse: Nurse) {
    this.resetAllFormArrays();
    if (nurse) {
      this.form.setValue({
        info: {
          ...nurse.info,
          expDate: nurse.info.expDate ? this.datePipe.transform(nurse.info.expDate, 'yyyy-MM-dd') : '',
          expDateManual: nurse.info.expDateManual ? this.datePipe.transform(nurse.info.expDateManual, 'yyyy-MM-dd') : ''
        },
        exp: {
          total: nurse.exp.total,
          cluster1: {
            expYears: nurse.exp.cluster1.cluster1Age,
            employer: nurse.exp.cluster1.employer1,
            tasks: this.nurseService.fromStringToAvailObject(nurse.exp.cluster1.tasks1, 'tasks1'),
            help: nurse.exp.cluster1.help1 ?? '',
          },
          cluster2: {
            expYears: nurse.exp.cluster2.cluster2Age,
            employer: nurse.exp.cluster2.employer2,
            tasks: this.nurseService.fromStringToAvailObject(nurse.exp.cluster2.tasks2, 'tasks2'),
            help: nurse.exp.cluster2.help2 ?? '',
          },
        },
        lang: {
          ...nurse.lang,
          certificates: ['']
        },
        reference: {
          f_surname: nurse.reference ? nurse.reference.f_surname : '',
          f_second_surname: nurse.reference ? nurse.reference.f_second_surname : ''
        },
        avail: {
          ...nurse.avail,
          days: this.nurseService.fromStringToAvailObject(nurse.avail.days, 'days'),
          hours: this.nurseService.fromStringToAvailObject(nurse.avail.hours, 'hours'),
          contract: this.nurseService.fromStringToAvailObject(nurse.avail.contract, 'contract')
        }
      }, { emitEvent: false });

      this.setCertificatesLenght(nurse.certificates.nurseCertificateList);
    }

    if (this.form.get('rating.notRated')?.value === true && this.checkNotRated) {
      this.form.get('rating.overall')?.valueChanges.subscribe((overall) => {
        if (overall > 0) {
          this.form.get('rating.notRated')?.setValue(false);
        } else {
          this.form.get('rating.notRated')?.setValue(true);
        }
      })
      this.checkNotRated = false;
    } else {
      this.checkNotRated = false;
    }
  }

  save(showMessage?: boolean) {
    if (this.form.valid) {
      if (this.isNew) {
        this.createNurse();
      } else {
        this.updateNurse(showMessage);
      }
    }
  }

  changeTab(tab: number) {
    if (this.form.dirty) {
      this.showAlert = true;
      this.nextTab = tab;
    } else {
      this.step = tab;
      this.nurseState.step = this.step;
    }
  }

  onAlertAction(save: boolean) {
    if (save && !this.isNew) {
      this.updateNurse();
    } else if (save && this.isNew) {
      this.createNurse();
    } else if (!save && this.goBackRequested) {
      if (this.nurse === undefined) {
        this.form.markAsPristine();
        this.form.reset();
        this.goBack();
      } else {
        this.form.markAsPristine();
        this.form.reset();
        this.formInitialize(this.nurse);
        this.goBack();
      }
    } else {
      this.form.markAsPristine();
      this.form.reset();
      this.formInitialize(this.nurse);
      // this.goBack();
    }
    this.showAlert = false;
    if (this.nextTab !== -1) {
      this.step = this.nextTab;
      this.nurseState.step = this.step;
      this.nextTab = -1;
    }
  }

  private async createNurse() {
    this.nurse = await this.processFormData(this.form.value);
    this.nurseService.createNurse(this.nurse).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        this.modalService.showSuccessMessage('Nuova badante creata.');
        const regex = /#(\w{2}-\d{5})/gm;
        const result = regex.exec(res.message);
        if (result) {
          this.nurseService.getSingleNurse(result[1]).pipe(takeUntil(this.unsubscribe)).subscribe((nurses) => {
            let nurse = nurses[0];
            if (nurse) {
              this.isNew = !this.isNew;
              this.nurse = nurse;
              this.form.markAsPristine();
              this.formInitialize(this.nurse);
            }
          });
        }
      },
      error: (error) => {
        if ((error.error as string).match('Key .* already exists.')) {
          this.modalService.showErrorMessage('Esiste già un profilo associato all\'indirizzo email fornito.');
        } else {
          this.modalService.showErrorMessage('Si è verificato un errore nella creazione della badante, si prega di riprovare.');
        }
      }
    });
  }

  private async updateNurse(showMessage?: boolean) {
    this.nurse = await this.processFormData(this.form.value);
    this.nurseService.updateNurse(this.nurse).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        if (showMessage) {
          this.modalService.showSuccessMessage('Badante modificata.');
        }
        this.publishNewCaregiverScoreUpdate();
        this.form.markAsPristine();
        this.formInitialize(this.nurse);
        this.generateCV(false);
      },
      error: (error) => {
        console.log(error);
        this.modalService.showErrorMessage(`Si è verificato un errore nell'aggiornamento della badante.`);
      }
    });
  }

  private async processFormData(data: any) {
    let nurse: Nurse = {
      docRef: this.nurse?.docRef ?? '',
      certificate: this.nurse?.certificate ?? '',
      info: {
        ...data.info,
        age: +data.info.age > 0 ? +data.info.age : 1,
        expDate: data.info.expDate !== '' ? new Date(data.info.expDate) : null,
        expDateManual: data.info.expDateManual !== '' ? new Date(data.info.expDateManual) : null,
        stopWhatsapp: data.info.stopWhatsapp ?? false,
        latitude: null,
        longitude: null
      },
      exp: {
        total: data.exp.total,
        cluster1: {
          cluster1Age: data.exp.cluster1.expYears ?? 0,
          employer1: data.exp.cluster1.employer,
          tasks1: this.nurseService.fromAvailObjectToString(data.exp.cluster1.tasks, 'tasks1'),
          help1: data.exp.cluster1.help
        },
        cluster2: {
          cluster2Age: data.exp.cluster2.expYears ?? 0,
          employer2: data.exp.cluster2.employer,
          tasks2: this.nurseService.fromAvailObjectToString(data.exp.cluster2.tasks, 'tasks2'),
          help2: data.exp.cluster2.help
        },
      },
      reference: {
        f_surname: data.reference.f_surname,
        f_second_surname: data.reference.f_second_surname
      },
      lang: {
        ...data.lang,
      },
      avail: {
        ...data.avail,
        days: this.nurseService.fromAvailObjectToString(data.avail.days, 'days'),
        hours: this.nurseService.fromAvailObjectToString(data.avail.hours, 'hours'),
        contract: this.nurseService.fromAvailObjectToString(data.avail.contract, 'contract')
      },
      rating: {
        ...this.caregiverRating ?? this.nurse?.rating ??
        {
          overall: 0,
          punctuality: 0,
          empathy: 0,
          behave: 0,
          communication: 0,
          attitude: '',
          dangerous: false,
          notRated: false
        }
      },
      engagement: {
        ...this.nurse?.engagement ?? {
          selected: 0,
          chosen: 0,
          discarded: 0,
          ignored: 0,
          substituted: 0,
          applications: 0,
          chosenDate: undefined,
          latestApplication: undefined
        }
      },
      certificates: {
        nurseCertificateList: data.lang.certificates
      },
      updateTimestamp: this.nurse ? this.nurse.updateTimestamp : new Date()
    };

    //calculate coordinates from address
    if(data.info.address === ''){
      let coordinates = {
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      }
      nurse = {
        ...nurse,
        info: {
          ...nurse.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }
    } else {
      if (this.oldAddress !== data.info.address) {
        let coordinates = await this.getCoordiantes(nurse)
        nurse = {
          ...nurse,
          info: {
            ...nurse.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }

      } else {
        let coordinates = {
          latitude: data.info.latitude,
          longitude: data.info.longitude,
        }
        nurse = {
          ...nurse,
          info: {
            ...nurse.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }
      }
    }
    // if (this.oldAddress !== data.info.address) {
    //   let coordinates = await this.getCoordiantes(nurse)
    //   nurse = {
    //     ...nurse,
    //     info: {
    //       ...nurse.info,
    //       latitude: coordinates.latitude,
    //       longitude: coordinates.longitude
    //     }
    //   }

    // } else {
    //   let coordinates = {
    //     latitude: data.info.latitude,
    //     longitude: data.info.longitude,
    //   }
    //   nurse = {
    //     ...nurse,
    //     info: {
    //       ...nurse.info,
    //       latitude: coordinates.latitude,
    //       longitude: coordinates.longitude
    //     }
    //   }
    // }

    return nurse;
  }

  private setNurse(bb: Nurse) {
    this.nurse = bb;
    this.nurseState.nurse = this.nurse;
    this.nurseState.step = this.step;
  }

  openModal(event: any) {
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById('modal-container')) {
      this.showModal = false;
    }
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
  }

  /* Experience form arrays methods */

  changeFormArrayLength(more: boolean, cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  resetFormArray(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    array.clear();
  }

  resetAllFormArrays() {
    this.resetCertificates();
    this.changeCertificatesLength(true);
  }

  getFormArrayControls(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    return array.controls;
  }


  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    return array.controls;
  }

  htmlToCanvas(showAlert: boolean) {
    console.log('Check pages:', this.currentPage, this.cvPages)
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;
      return;
    }
    const element = document.getElementById('cv-page-container') as HTMLElement;

    html2canvas(element, { allowTaint: true, useCORS: true, scale: 2, }).then(canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        this.certificateService.createNursePDFCVFromHTML(this.canvases, this.nurse, this.isNew, showAlert,
          this.stateManager, `${environment.baseURL}/nurses`, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas(showAlert) }, 1000);
    })
  }

  cvPreview() {
    if (!this.generatePages) {
      this.router.navigate(['nurse-detail', this.nurse.docRef, 'cv'], { state: { data: this.nurse } });
    }
  }

  openCertificatePage() {
    /* const config: FillCertificateConfig = {
      nurse: this.babySitter,
      stateManager: this.stateManager,
      isProduction: environment.production,
      mailServiceURL: environment.mailServiceURL,
      babysitterApiURL: environment.babysitterServiceURL,
      showModal: true
    }
    this.certificateService.setConfig(config);
    this.router.navigate(['nurse-detail', this.babySitter.docRef, 'fill-certificate']); */
  }

  computeTotalExp() {
    let value = this.form.get('exp')?.value;
    let expSum = value.cluster1.expYears + value.cluster2.expYears;
    let age = this.form.get('info.age')?.value;
    this.form.get('exp.total')?.setValue(Math.max(value.cluster1.expYears, value.cluster2.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    this.form.get('exp.total')?.markAsDirty();
    this.form.get('exp.total')?.markAsTouched();
  }

  onCaregiverEvaluationChanged(newCaregiverEvaluation: CaregiverEvaluation) {
    this.caregiverRating = newCaregiverEvaluation;
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  updateProvincia(newValue: string) {
    this.form.get('info.provincia')?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get('info.provincia')?.markAsDirty();
  }

  sendCoursesWhatsapp(){
    let body = {
      type: 'CAREGIVERS_COURSES',
      caregiverPhone:this.nurse.info.phone,
      header: '',
      caregiverName:this.nurse.info.name,
      courseLink:'Badanti-159573353c6e8035ad28e65f107fbe01'
    }
    this.http.post(`${environment.cloudFunctionsBaseURL}/sendWhatsapp`, body).pipe(
      tap(() => {
        this.toastService.setAction("Messaggio Whatsapp inviato correttamente!", true);
      }),
      catchError((error) => {
        console.error("Error on sending Whatsapp", error);
        this.toastService.setAction("Errore durante l'invio del messaggio Whatsapp!", false);
        return of();
      }),
      takeUntil(this.unsubscribe)).subscribe()
  }


  private publishNewCaregiverScoreUpdate() {
    if (this.caregiverRating) {
      // Insert code to save new caregiver evaluation in the database
      const record: CaregiverEvaluationRecord = {
        ...this.caregiverRating,
        id: null,
        docRef: this.nurse.docRef,
        operator: this.currentUser,
        timestamp: new Date()
      }
      this.caregiverEvaluationService.addRecord(record).subscribe((response: CaregiverEvaluationRecord) => console.log(record))
      this.caregiverRating = undefined;
    }
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Nurse) {

    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
