import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CaregiverScore, CaregiverScoreType } from './../../models/caregiver-score.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CaregiverScoreRecord } from '../../models/caregiver-score-record.model';
import { Page } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class CaregiverScoreService {
  private baseURL = `${environment.baseURL}/caregiver-scoring`;

  constructor(private http: HttpClient) { }

  getScore(docRef: string): Observable<CaregiverScore> {
    return this.http.get<CaregiverScore>(`${this.baseURL}/${docRef}/current-score`);
  }

  updateScore(docRef: string, score: number, type: CaregiverScoreType) {
    return this.http.patch(`${this.baseURL}/${docRef}/update-score?score=${score}&type=${type}`, {}, {
      headers: {
        'Accept': 'text/plain'
      },
      responseType: 'text'
    });
  }

  getScoreHistory(docRef: string, page: number, pageSize: number): Observable<Page<CaregiverScoreRecord>> {
    return this.http.get<Page<CaregiverScoreRecord>>(`${this.baseURL}/${docRef}/score-records?page=${page}&size=${pageSize}`);
  }
}
