import { DEFAULT_PAYMENT_FILTERS, PaymentFilters } from '../payment-service/payment.service';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PaymentService } from '../payment-service/payment.service';
import { Router, RouterModule } from '@angular/router';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FHService, ModalService, PaymentRow, ProvinceFilterComponent } from 'npx-family-happy-common';
import { NgbCollapseModule, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { PaymentStatusBadgeComponent } from 'src/app/shared/components/payment-status-badge/payment-status-badge.component';
import { OperatorFilterComponent } from 'src/app/shared/components/custom-select/operator-filter/operator-filter.component';

@Component({
  selector: 'app-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbCollapseModule, FHServiceSelectComponent,
    RouterModule, NgbPaginationModule, AlertComponent, PaymentStatusBadgeComponent, ProvinceFilterComponent, OperatorFilterComponent]
})

export class PaymentDashboardComponent implements OnInit, OnDestroy {
  filters = new UntypedFormGroup({
    dateFrom: new UntypedFormControl(null),
    dateTo: new UntypedFormControl(null),
    serviceID: new UntypedFormControl(null),
    email: new UntypedFormControl(null),
    province: new UntypedFormControl(''),
    invoiced: new UntypedFormControl(null),
    amount: new UntypedFormControl(null),
    seller: new UntypedFormControl(null),
    status: new UntypedFormControl(null)
  })
  payments: PaymentRow[] = [];
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  resetServiceValue = false

  showDeleteConfirm = false;
  isCollapsed = true;
  selectedPaymentID = -1;
  selectedOption = -1;

  filters$!: Observable<any>
  serviceID!: number | null

  origin = `${location.protocol}//${location.host}`;

  private unsubscribe = new Subject<void>();

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private modalService: ModalService,
    private ngbModalService: NgbModal
  ) {
    this.updateFilters()
  }

  ngOnInit(): void {
    this.loadPayments();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onPageChange() {
    if (this.page > 0) {
      this.loadPayments();
    }
  }

  createPayment() {
    this.router.navigate(['/payments/create']);
  }

  openPaymentDetail(id: number) {
    this.router.navigate(['/payments/' + id]);
  }

  deletePayment(id: number) {
    this.selectedPaymentID = id;
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedPaymentID > -1) {
      this.paymentService.deletePaymentById(this.selectedPaymentID).subscribe(() => {
        this.modalService.showSuccessMessage('Pagamento eliminato');
        this.loadPayments();
      });
    }
    this.showDeleteConfirm = false;
  }

  applyFilters() {
    const filtersValue = this.filters.value;
    this.page = 1; // Reset page on filters change
    const formattedFilters = {
      ...filtersValue,
      dateFrom: filtersValue.dateFrom === null ? null : new Date(filtersValue.dateFrom).toISOString(),
      dateTo: filtersValue.dateTo === null ? null : new Date(filtersValue.dateTo).toISOString(),
    };
    this.paymentService.setPaymentFilters(formattedFilters)
    this.loadPayments();
  }

  resetFilters() {
    this.paymentService.setPaymentFilters(DEFAULT_PAYMENT_FILTERS)
    this.updateFHService(null)
    this.loadPayments();
  }

  updateFilters() {
    this.paymentService.getPaymentFilters().subscribe((filters) => {
      this.serviceID = filters.serviceID;
      this.filters.patchValue({
        ...filters,
        dateFrom: filters.dateFrom ? filters.dateFrom.split('.')[0] : null,
        dateTo: filters.dateTo ? filters.dateTo.split('.')[0] : null,
      })
    })
  }

  updateProvincia(newValue: string) {
    this.filters.get('province')?.setValue(newValue);
    this.filters.markAllAsTouched();
    this.filters.get('province')?.markAsDirty();
  }

  updateSeller(newValue: string) {
    this.filters.get('seller')?.setValue(newValue);
    this.filters.markAllAsTouched();
    this.filters.get('seller')?.markAsDirty();
  }

  updateFHService(service: FHService | null) {
    if (service === null) {
      this.resetServiceValue = true
    }
    this.filters.get('serviceID')?.setValue(service?.id);
    this.filters.markAllAsTouched();
    this.filters.get('serviceID')?.markAsDirty();
  }

  exportRecords(content: TemplateRef<any>) {
    this.selectedOption = -1;
    this.ngbModalService.open(content, { centered: true })
      .result
      .then((confirm) => {
        console.log(confirm, this.selectedOption);
        if (confirm) {
          switch (this.selectedOption) {
            case 1:
              this.paymentService.exportExcel(this.filters.value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((blob) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'payments.xlsx';
                  link.click();
                });
              break;
            case 2:
              const currentDate = new Date();
              const dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 1, 0, 0);
              const dateFrom = new Date(dateTo.getTime());
              dateFrom.setDate(dateFrom.getDate() - 1);

              const filters: PaymentFilters = {
                ...DEFAULT_PAYMENT_FILTERS,
                dateFrom: dateFrom.toISOString().substring(0, 19), // Truncate date to seconds
                dateTo: dateTo.toISOString().substring(0, 19) // Truncate date to seconds
              }
              this.paymentService.exportExcel(filters)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((blob) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'payments.xlsx';
                  link.click();
                });
              break;
            case 3:
              this.paymentService.exportExcel(DEFAULT_PAYMENT_FILTERS)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((blob) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = 'payments.xlsx';
                  link.click();
                });
              break;
            default:
              break;
          }
        }
      }).catch((error) => console.log(error));
  }

  getYesterdayDate() {
    const date = new Date();
    date.setTime(date.getTime() - 86400000);
    return date.toLocaleDateString('it-IT', { day: '2-digit', month: 'short', year: 'numeric' });
  }

  private loadPayments() {
    this.paymentService.getAllPayments(this.page - 1, this.pageSize, this.filters.value).subscribe((paymentPage) => {
      this.payments = paymentPage.content.map((item) => ({ ...item, selected: false }));
      this.page = paymentPage.number + 1;
      this.pageSize = paymentPage.size;
      this.collectionSize = paymentPage.totalElements;
      this.resetServiceValue = false
    })
  }

}
