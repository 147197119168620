<div class="bigger-container">
  <div class="left-section">
    <p [ngClass]="{'selected': step === 0}" (click)="changeTab(0)">Anagrafica</p>
    <p [ngClass]="{'selected': step === 1}" (click)="changeTab(1)">Esperienza</p>
    <p [ngClass]="{'selected': step === 2}" (click)="changeTab(2)">Referenze</p>
    <p [ngClass]="{'selected': step === 3}" (click)="changeTab(3)">Formazione</p>
    <p [ngClass]="{'selected': step === 4}" (click)="changeTab(4)">Disponibilità</p>
    <p [ngClass]="{'selected': step === 5}" (click)="changeTab(5)">Valutazione</p>
    <p [ngClass]="{'selected': step === 6}" (click)="changeTab(6)">Coinvolgimento</p>
  </div>
  <div class="right-section">
    <div id="backButton" class="back-button" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
      <p>Torna alla lista</p>
    </div>
    <div class="smaller-container">
      <p class="smaller-text">{{isNew ? 'Crea' : 'Modifica'}} Babysitter</p>
      <div class="smaller-bar"></div>
    </div>
    <form [formGroup]="form" class="mt-5 px-3">
      <div *ngIf="step === 0" class="grid" formGroupName="info">
        <div>
          <label class="label" for="name">Nome<span class="red">*</span></label>
          <input type="text" id="name" formControlName="name">
        </div>
        <div>
          <label class="label" for="surname">Cognome<span class="red">*</span></label>
          <input type="text" id="surname" formControlName="surname">
        </div>
        <div>
          <label class="label" for="email">Email<span class="red">*</span></label>
          <input type="text" id="email" formControlName="email">
        </div>
        <div>
          <label class="label" for="phone">Cellulare<span class="red">*</span></label>
          <input type="text" id="phone" formControlName="phone">
        </div>
        <div>
          <label class="label" for="address">Indirizzo</label>
          <input type="text" id="address" formControlName="address">
        </div>
        <div>
          <label class="label" for="city">Città</label>
          <input type="text" id="city" formControlName="city">
        </div>
        <div>
          <label class="label" for="zone">Zona</label>
          <input type="text" id="zone" formControlName="zone">
        </div>
        <div>
          <label class="label" for="cap">CAP</label>
          <input type="text" id="cap" formControlName="cap">
        </div>
        <div>
          <label class="label" for="age">Età</label>
          <input type="number" id="age" formControlName="age" min="0" max="99">
        </div>
        <div>
          <label class="label" for="birthDate">Data di Nascita</label>
          <input type="date" id="birthDate" formControlName="birthDate">
        </div>
        <div>
          <label class="label" for="fiscalcode">Codice Fiscale</label>
          <input type="text" id="fiscalcode" style="text-transform: uppercase;" formControlName="fiscalcode">
        </div>
        <div>
          <label class="label" for="iban">IBAN</label>
          <input type="text" id="iban" formControlName="iban">
        </div>
        <div>
          <label class="label" for="provincia">Provincia di interesse</label>
          <app-province-filter [provincia]="form.get('info.provincia')?.value" (valueChange)="updateProvincia($event)"></app-province-filter>
        </div>
        <div>
          <label class="label">Video</label>
          <input type="text" formControlName="video">
        </div>
        <div>
          <label class="label">Certificato F+H</label>
          <p
            *ngIf="!(!isNew && babySitter?.rating ? ((babySitter.rating.overall >= 6) && !babySitter?.rating?.dangerous) : false)">
            La babysitter non soddisfa i requisiti per la certificazione.</p>
          <div
            *ngIf="!isNew && babySitter?.rating ? ((babySitter.rating.overall >= 6) && !babySitter?.rating?.dangerous) : false">
            <button class="secondary-button" (click)="openCertificatePage()">Crea</button>
            <a *ngIf="!isNew && babySitter?.certificate" class="tertiary-button" download
              href="{{babySitter.certificate}}" target="_blank">Scarica</a>
          </div>
        </div>
        <div>
          <label class="label">CV Family Happy</label>
          <div style="grid-template-columns: repeat(3, 1fr)">
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button" (click)="generateCV(true)">Crea</button>
            </div>
            <a *ngIf="babySitter ? (babySitter.info ? babySitter.info.resumeHidden : false) : false"
              class="tertiary-button" download
              [href]="babySitter ? (babySitter.info ? babySitter.info.resumeHidden : '') : ''"
              target="_blank">Scarica</a>
            <a *ngIf="babySitter ? (babySitter.info ? babySitter.info.resumeHidden : false) : false"
              class="tertiary-button" (click)="cvPreview()"
              [ngStyle]="{ 'opacity': generatePages ? '50%' : '100%' }">Invia</a>
          </div>
        </div>
        <div>
          <label class="label">Certificato Disostruzione</label>
          <div>
            <a [href]="babySitter.certificates.disostruzioneCertURL"
              *ngIf="babySitter?.certificates?.disostruzioneCertURL" target="_blank" class="tertiary-button">Scarica</a>
            <p *ngIf="!babySitter?.certificates?.disostruzioneCertURL" style="grid-column: 1/3">La babysitter non ha
              ancora ottenuto la certificazione.</p>
          </div>
        </div>
        <div>
          <label class="label">CV</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input type="file" accept=".pdf" (change)="uploadFile($event, 'info.resume')">
            </div>
            <a *ngIf="babySitter ? (babySitter.info ? babySitter.info.resume : false) : false" class="tertiary-button"
              download [href]="babySitter ? (babySitter.info ? babySitter.info.resume : '') : ''"
              target="_blank">Scarica</a>
          </div>
        </div>
        <div *ngIf="!isNew; else elseBlock">
          <label class="label">Foto</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input id="image-selection" type="file" accept="image/*" (change)="openModal($event)">
            </div>
            <a *ngIf="babySitter ? (babySitter.info ? babySitter.info.photo : false) : false" class="tertiary-button"
              download [href]="babySitter ? (babySitter.info ? babySitter.info.photo : '') : ''"
              target="_blank">Scarica</a>
          </div>
        </div>
        <ng-template #elseBlock>
          <div>
            <label class="label">Foto</label>
            <p id="foto">Puoi aggiungere la foto nella sezione "modifica" del profilo</p>
          </div>
        </ng-template>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="stopWhatsapp">Stop Whatsapp</label>
          <input type="checkbox" id="stopWhatsapp" formControlName="stopWhatsapp">
        </div>
        <div class="input-field">
          <label for="subscription" class="label">Stato Abbonamento</label>
          <input type="text" formControlName="subscription" id="subscription" readonly>
        </div>
        <div class="input-field">
          <label class="label" for="expDate">Scadenza Abbonamento</label>
          <input type="date" id="expDate" formControlName="expDate" readonly>
        </div>
        <div class="input-field">
          <label for="manualSubscription" class="label">Stato Abbonamento Manuale</label>
          <select formControlName="manualSubscription" id="manualSubscription">
            <option value="">Scegli un'opzione</option>
            <option value="ABBONATO">ABBONATO</option>
            <option value="EXABBONATO">EX ABBONATO</option>
          </select>
        </div>
        <div class="input-field">
          <label class="label" for="expDateManual">Scadenza Abbonamento Manuale</label>
          <input type="date" id="expDateManual" formControlName="expDateManual">
        </div>
        <!-- <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="fhsub">Iscrizione a Family+Happy</label>
          <input type="checkbox" id="fhsub" formControlName="fhsub">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="fhsubmanual">Iscrizione a Family+Happy Manuale</label>
          <input type="checkbox" id="fhsubmanual" formControlName="fhSubManual">
        </div>
        <div *ngIf="!form.get('info.fhSubManual')!.value"></div>
        <div class="input-field" *ngIf="form.get('info.fhSubManual')!.value">
          <label class="label" for="expdate">Scadenza iscrizione</label>
          <input type="date" id="expdate" formControlName="expDate">
        </div> -->
        <div *ngIf="babySitter !== undefined">
          <label class="label" for="subInterest">ID Babysitter:</label>
          <p style="font-weight: bold;">{{babySitter.docRef}}</p>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="description">Descrizione</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="description" maxlength="426"
            formControlName="description"></textarea>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="note">Note</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="note" maxlength="2048"
            formControlName="notes"></textarea>
        </div>
      </div>
      <div *ngIf="step === 1" class="grid" style="grid-template-columns: 1fr 1fr; gap: 2em;" formGroupName="exp">
        <div formGroupName="cluster1"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster1Age">Anni di esperienza 0-6 mesi</label>
            <input type="number" id="cluster1Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster1.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster1Employer">Presso</label>
              <select id="cluster1Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Asilo">Asilo</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="hygiene">Cambio pannolini e igiene del bambino</label>
                <input type="checkbox" id="hygiene" formControlName="hygiene" name="hygiene">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="sleep-1">Accompagnamento alla nanna</label>
                <input type="checkbox" id="sleep-1" formControlName="sleep" name="sleep-1">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="food">Preparazione pappa</label>
                <input type="checkbox" id="food" formControlName="food" name="food">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="weaning">Svezzamento</label>
                <input type="checkbox" id="weaning" formControlName="weaning" name="weaning">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="food-safety-1">Conoscenza tagli sicuri degli alimenti e
                  autosvezzamento</label>
                <input type="checkbox" id="food-safety-1" formControlName="foodSafety" name="food-safety-1">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other">Altro</label>
                <input type="checkbox" id="other" formControlName="other" name="other">
              </div>
              <div *ngIf="form.get('exp.cluster1.tasks.other')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(1, 'otherTasks'), index as i">
                  <label class="label" for="extra-task-1-{{ i + 1 }}">Attività extra {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="extra-task-1-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 1, 'otherTasks')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster1.otherTasks')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 1, 'otherTasks')">-</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="cluster2"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster2Age">Anni di esperienza 7-24 mesi</label>
            <input type="number" id="cluster2Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster2.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster2Employer">Presso</label>
              <select id="cluster2Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Asilo">Asilo</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="cry">Gestione del pianto</label>
                <input type="checkbox" id="cry" formControlName="cry" name="cry">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="crawling">Stimolo al gattonamento</label>
                <input type="checkbox" id="crawling" formControlName="crawling" name="crawling">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="montessori">Approccio al gioco con metodo Montessori</label>
                <input type="checkbox" id="montessori" formControlName="montessori" name="montessori">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="language">Attestato di sviluppo del linguaggio</label>
                <input type="checkbox" id="language" formControlName="language" name="language">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="food-safety-2">Conoscenza tagli sicuri degli alimenti e
                  autosvezzamento</label>
                <input type="checkbox" id="food-safety-2" formControlName="foodSafety" name="food-safety-2">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="motory">Stimolo allo sviluppo motorio</label>
                <input type="checkbox" id="motory" formControlName="motory" name="motory">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other2">Altro</label>
                <input type="checkbox" id="other2" formControlName="other" name="other2">
              </div>
              <div *ngIf="form.get('exp.cluster2.tasks.other')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(2, 'otherTasks'), index as i">
                  <label class="label" for="extra-task-2-{{ i + 1 }}">Attività extra {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="extra-task-2-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 2, 'otherTasks')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster2.otherTasks')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 2, 'otherTasks')">-</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="cluster3"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster3Age">Anni di esperienza 2-5 anni</label>
            <input type="number" id="cluster3Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster3.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster3Employer">Presso</label>
              <select id="cluster3Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Asilo">Asilo</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="sleep-2">Accompagnamento al sonno</label>
                <input type="checkbox" id="sleep-2" formControlName="sleep" name="sleep-2">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="illness">Gestione della malattia</label>
                <input type="checkbox" id="illness" formControlName="illness" name="illness">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="motory-activities">Attività motoria all’aperto</label>
                <input type="checkbox" id="motory-activities" formControlName="motoryActivities"
                  name="motory-activities">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="emotion-management">Gestione emotiva degli incontri con altri bambini</label>
                <input type="checkbox" id="emotion-management" formControlName="emotionManagement"
                  name="emotion-management">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="spannolinamento">Avvio dello spannolinamento</label>
                <input type="checkbox" id="spannolinamento" formControlName="spannolinamento" name="spannolinamento">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="creative-games">Gioco creativo</label>
                <input type="checkbox" id="creative-games" formControlName="creativeGames" name="creative-games">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other3">Altro</label>
                <input type="checkbox" id="other3" formControlName="other" name="other3">
              </div>
              <div *ngIf="form.get('exp.cluster3.tasks.creativeGames')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(3, 'gamesDesc'), index as i">
                  <label class="label" for="game-{{ i + 1 }}">Gioco {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="game-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 3, 'gamesDesc')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster3.gamesDesc')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 3, 'gamesDesc')">-</button>
                </div>
              </div>
              <div *ngIf="form.get('exp.cluster3.tasks.other')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(3, 'otherTasks'), index as i">
                  <label class="label" for="extra-task-3-{{ i + 1 }}">Attività extra {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="extra-task-3-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 3, 'otherTasks')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster3.otherTasks')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 3, 'otherTasks')">-</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="cluster4"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster4Age">Anni di esperienza 5+ anni</label>
            <input type="number" id="cluster4Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster4.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster4Employer">Presso</label>
              <select id="cluster4Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Asilo">Asilo</option>
                <option value="Scuola primaria">Scuola primaria</option>
                <option value="Scuola secondaria">Scuola secondaria</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="homeworks">Portare a termine i compiti</label>
                <input type="checkbox" id="homeworks" formControlName="homeworks" name="homeworks">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="free-time">Gestione del tempo libero</label>
                <input type="checkbox" id="free-time" formControlName="freeTime" name="free-time">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="extra-activities">Accompagnamento alle attività extra scolastiche</label>
                <input type="checkbox" id="extra-activities" formControlName="extra" name="extra-activities">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other4">Altro</label>
                <input type="checkbox" id="other4" formControlName="other" name="other4">
              </div>
              <div *ngIf="form.get('exp.cluster4.tasks.freeTime')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(4, 'freeTimeDesc'), index as i">
                  <label class="label" for="freetime-{{ i + 1 }}">Attività tempo libero {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="freetime-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 4, 'freeTimeDesc')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster4.freeTimeDesc')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 4, 'freeTimeDesc')">-</button>
                </div>
              </div>
              <div *ngIf="form.get('exp.cluster4.tasks.other')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(4, 'otherTasks'), index as i">
                  <label class="label" for="extra-task-4-{{ i + 1 }}">Attività extra {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="extra-task-4-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 4, 'otherTasks')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster4.otherTasks')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 4, 'otherTasks')">-</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label class="label label-highlight" for="totalExp">Anni di esperienza complessivi</label>
          <input type="number" id="totalExp" min=0 formControlName="total">
        </div>

        <div>
          <button type="button" class="secondary-button" (click)="computeTotalExp()">Calcolo automatico</button>
        </div>

        <div formGroupName="homework"
          style="display: grid; grid-template-columns: 1fr 1fr; gap: 2em; grid-column: 1/3; margin-top: 50px;">
          <div style="grid-column: 1/2">
            <label class="label label-highlight" style="background-color: var(--fh-secondary-color);"
              for="homeworkExp">Anni di esperienza aiutocompiti</label>
            <input type="number" id="homeworkExp" min=0 formControlName="homeworkExp">
          </div>

          <div *ngIf="form.get('exp.homework.homeworkExp')?.value"
            style="grid-column: 1/3; grid-template-columns: 1fr 1fr; gap: 2em; align-items: start;">
            <div style="display: flex; flex-direction: column; gap: 10px;">
              <legend>Età</legend>
              <div>
                <label class="label" for="expChildAge1">Bambino 1</label>
                <div class="d-block">
                  <input type="number" style="width: 100%;" id="expChildAge1" min=0 max=15 formControlName="childAge1">
                  <p style="color: red; font-size: 0.85em;" class="pt-2" *ngIf="childAge1!.hasError('max')">
                    * Aiutocompiti non è disponibile per bambini sopra i 15 anni.
                  </p>
                </div>
                <!-- <input type="number" id="expChildAge1" min=0 max=15 formControlName="childAge1">
                <p style="color: red; font-size: 0.85em;" *ngIf="childAge1!.hasError('max')">
                  L'età non può superare 15.
                </p> -->
              </div>
              <div>
                <label class="label" for="expChildAge2">Bambino 2</label>
                <div class="d-block">
                  <input type="number" style="width: 100%;" id="expChildAge2" min=0 max=15 formControlName="childAge2">
                  <p style="color: red; font-size: 0.85em;" class="pt-2" *ngIf="childAge2!.hasError('max')">
                    * Aiutocompiti non è disponibile per bambini sopra i 15 anni.
                  </p>
                </div>
                <!-- <input type="number" id="expChildAge2" min=0 max=15 formControlName="childAge2"> -->
              </div>
              <div>
                <label class="label" for="expChildAge3">Bambino 3</label>
                <div class="d-block">
                  <input type="number" style="width: 100%;" id="expChildAge3" min=0 max=15 formControlName="childAge3">
                  <p style="color: red; font-size: 0.85em;" class="pt-2" *ngIf="childAge3!.hasError('max')">
                    * Aiutocompiti non è disponibile per bambini sopra i 15 anni.
                  </p>
                </div>
                <!-- <input type="number" id="expChildAge3" min=0 max=15 formControlName="childAge3"> -->
              </div>
            </div>

            <div formGroupName="subjects" style="display: flex; flex-direction: column; gap: 10px;">
              <legend>Materie</legend>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="math">Matematica</label>
                <input type="checkbox" id="math" formControlName="math" name="math">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="italian">Italiano</label>
                <input type="checkbox" id="italian" formControlName="italian" name="italian">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="english">Inglese</label>
                <input type="checkbox" id="english" formControlName="english" name="english">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="french">Francese</label>
                <input type="checkbox" id="french" formControlName="french" name="french">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="homework-all">Un po' di tutto</label>
                <input type="checkbox" id="homework-all" formControlName="all" name="homework-all">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="homework-other">Altro</label>
                <input type="checkbox" id="homework-other" formControlName="other" name="homework-other">
              </div>
              <div *ngIf="form.get('exp.homework.subjects.other')?.value">
                <label class="label" for="homework-other-desc">Altra materia</label>
                <input type="text" id="homework-other-desc" formControlName="otherDesc" name="homework-other-desc">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="step === 2" class="grid" formGroupName="reference">
        <div>
          <label class="label" for="f_surname">Cognome prima famiglia</label>
          <input type="text" id="f_surname" formControlName="f_surname">
        </div>
        <div>
          <label class="label" for="f_second_surname">Cognome seconda famiglia</label>
          <input type="text" id="f_second_surname" formControlName="f_second_surname">
        </div>
      </div>
      <div *ngIf="step === 3" formGroupName="lang">
        <div class="grid">
          <div>
            <label class="label" for="title">Titolo di studio</label>
            <select formControlName="title">
              <option value="Medie inferiori">Medie inferiori</option>
              <option value="Medie superiori">Medie superiori</option>
              <option value="Laurea">Laurea</option>
            </select>
          </div>
          <fieldset style="width: 100%;" formGroupName="specialities">
            <legend>Qualifiche speciali</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="autistic">Spettro autistico</label>
              <input type="checkbox" id="autistic" formControlName="autistic">
              <label class="label" for="dislessia">Dislessia</label>
              <input type="checkbox" id="dislessia" formControlName="dislessia">
              <label class="label" for="doula">Doula</label>
              <input type="checkbox" id="doula" formControlName="doula">
            </div>
          </fieldset>
          <div [ngStyle]="{'opacity': form.get('lang.title')?.value !== 'Laurea' ? '70%' : '100%'}"
            style="align-items: start">
            <label class="label" for="graduationType">Tipo di laurea</label>
            <input type="text" id="graduationType" formControlName="graduationType" />
          </div>
          <fieldset style="width: 100%;" formGroupName="certificates">
            <legend>Certificati</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="disostruzione">Disostruzione</label>
              <input type="checkbox" id="disostruzione" formControlName="disostruzione">
              <label class="label" for="emotiva">Intelligenza emotiva</label>
              <input type="checkbox" id="emotiva" formControlName="emotiva">
              <div style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getCertificatesFormControls(), index as i">
                  <label class="label" for="otherCert-{{ i + 1 }}">Certificato {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="otherCert-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button" (click)="changeCertificatesLength(true)">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('lang.certificates.others')?.value.length > 1"
                    (click)="changeCertificatesLength(false)">-</button>
                </div>
              </div>
            </div>
          </fieldset>
          <div>
            <label class="label" for="english">Inglese</label>
            <select formControlName="english">
              <option value="Non lo parlo">Non lo parlo</option>
              <option value="Sufficiente">Sufficiente</option>
              <option value="Ottimo">Ottimo</option>
              <option value="Madrelingua">Madrelingua</option>
            </select>
          </div>
          <div>
            <label class="label" for="french">Francese</label>
            <select formControlName="french">
              <option value="Non lo parlo">Non lo parlo</option>
              <option value="Sufficiente">Sufficiente</option>
              <option value="Ottimo">Ottimo</option>
              <option value="Madrelingua">Madrelingua</option>
            </select>
          </div>
          <div>
            <label class="label" for="italian">Italiano</label>
            <select formControlName="italian">
              <option value="Non lo parlo">Non lo parlo</option>
              <option value="Sufficiente">Sufficiente</option>
              <option value="Ottimo">Ottimo</option>
              <option value="Madrelingua">Madrelingua</option>
            </select>
          </div>
          <div>
            <label class="label" for="german">Tedesco</label>
            <select formControlName="german">
              <option value="Non lo parlo">Non lo parlo</option>
              <option value="Sufficiente">Sufficiente</option>
              <option value="Ottimo">Ottimo</option>
              <option value="Madrelingua">Madrelingua</option>
            </select>
          </div>
          <div>
            <label class="label" for="spanish">Spagnolo</label>
            <select formControlName="spanish">
              <option value="Non lo parlo">Non lo parlo</option>
              <option value="Sufficiente">Sufficiente</option>
              <option value="Ottimo">Ottimo</option>
              <option value="Madrelingua">Madrelingua</option>
            </select>
          </div>
        </div>
        <div class="mt-5">
          <label class="label pe-4" for="courses">Corsi di formazione: </label>
          <div [formGroup]="coursesForm" style="max-width: 20%;" class="d-flex flex-column">
            <select class="my-3" formControlName="courses">
              <option disabled selected value="">Seleziona corso</option>
              <option value="Montessori">Metodo Montessori</option>
              <option value="all">Tutti i corsi</option>
            </select>
            <button class="btn btn-secondary" style="color:white" id="courses" (click)="sendCoursesWhatsapp()">Invia corsi Whatsapp</button>
          </div>
        </div>
      </div>
      <div *ngIf="step === 4" class="grid" formGroupName="avail">
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="babysitter">Babysitter</label>
          <input type="checkbox" id="babysitter" formControlName="babysitter">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="cleaning">Aiuto pulizie</label>
          <input type="checkbox" id="cleaning" formControlName="cleaning">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="cooking">Preparazione pasti</label>
          <input type="checkbox" id="cooking" formControlName="cooking">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="homework">Aiuto compiti</label>
          <input type="checkbox" id="homework" formControlName="homework">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="petsitter">Petsitter</label>
          <input type="checkbox" id="petsitter" formControlName="petsitter">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="taxi">Taxisitter Automunita</label>
          <input type="checkbox" id="taxi" formControlName="taxi">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="taxi-no-car">Taxisitter con auto Fornita dai Genitori</label>
          <input type="checkbox" id="taxi-no-car" formControlName="taxiNoCar">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="walking">Taxisitter (a piedi)</label>
          <input type="checkbox" id="walking" formControlName="walking">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="licence">Patente</label>
          <input type="checkbox" id="licence" formControlName="licence">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="car">Automunita</label>
          <input type="checkbox" id="car" formControlName="car">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="smoker">Fumatrice</label>
          <input type="checkbox" id="smoker" formControlName="smoker">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="coliving">Disp. Convivenza</label>
          <input type="checkbox" id="coliving" formControlName="coliving">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="relocation">Disp. Trasferte</label>
          <input type="checkbox" id="relocation" formControlName="relocation">
        </div>

        <div style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;" formGroupName="days">
          <fieldset style="width: 100%;">
            <legend>Giorni disponibili</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="sun">Domenica</label>
              <input type="checkbox" id="sun" formControlName="sun">
              <label class="label" for="mon">Lunedì</label>
              <input type="checkbox" id="mon" formControlName="mon">
              <label class="label" for="tue">Martedì</label>
              <input type="checkbox" id="tue" formControlName="tue">
              <label class="label" for="wed">Mercoledì</label>
              <input type="checkbox" id="wed" formControlName="wed">
              <label class="label" for="thu">Giovedì</label>
              <input type="checkbox" id="thu" formControlName="thu">
              <label class="label" for="fri">Venerdì</label>
              <input type="checkbox" id="fri" formControlName="fri">
              <label class="label" for="sat">Sabato</label>
              <input type="checkbox" id="sat" formControlName="sat">
            </div>
          </fieldset>
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;margin-top: 20px;">
          <fieldset style="width: 100%;" formGroupName="hours">
            <legend>Orari di disponibilità</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="mor">Mattino</label>
              <input type="checkbox" id="mor" formControlName="mor">
              <label class="label" for="aft">Pomeriggio</label>
              <input type="checkbox" id="aft" formControlName="aft">
              <label class="label" for="eve">Sera</label>
              <input type="checkbox" id="eve" formControlName="eve">
            </div>
          </fieldset>
          <fieldset style="width: 100%;" formGroupName="contract">
            <legend>Interessi per incarichi</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="occ">Occasionali</label>
              <input type="checkbox" id="occ" formControlName="occ">
              <label class="label" for="part">Part-time</label>
              <input type="checkbox" id="part" formControlName="part">
              <label class="label" for="full">Full-time</label>
              <input type="checkbox" id="full" formControlName="full">
            </div>
          </fieldset>
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;margin-top: 20px;">
          <fieldset style="width: 100%;">
            <legend>Tariffa netta indicativa</legend>
            <lib-virtual-scroll [label]="'tariffa minima'" [values]="100" [formCtrl]="minWage | formControl"></lib-virtual-scroll>
            <lib-virtual-scroll [label]="'tariffa massima'" [values]="100" [formCtrl]="maxWage | formControl"></lib-virtual-scroll>
          </fieldset>
        </div>
      </div>
      <app-caregiver-evaluation *ngIf="step === 5" [docRef]="babysitterID" [rating]="babySitter !== undefined ? babySitter.rating : defaultRating" (dataUpdated)="onCaregiverEvaluationChanged($event)"></app-caregiver-evaluation>
      <app-caregiver-engagement *ngIf="step === 6" [engagement]="babySitter !== undefined ? babySitter.engagement : defaultEngagement" [docRef]="babysitterID"></app-caregiver-engagement>
    </form>
    <div class="buttons-container">
      <button *ngIf="form.valid && form.touched && form.dirty" class="my-button" (click)="save(true)">Salva</button>
      <button *ngIf="!(form.valid && form.touched && form.dirty)" class="my-button disabled">Salva</button>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" (save)="onAlertAction($event)" [data]="{ type: undefined }"
  [title]="'Attenzione, alcuni dati sono stati modificati!'" [message]="'Vuoi salvare le modifiche effettuate?'">
</app-alert>

<div class="modal" id="modal-container" *ngIf="showModal" (click)="dismiss($event)">
  <div class="modal-content">
    <app-image-cropper [imageSource]="sanitize(chosenPhoto)" (outputEvent)="getCroppedPhoto($event)">
    </app-image-cropper>
    <div class="modal-button-container">
      <button class="secondary-button" (click)="cropperAbort()">Annulla</button>
      <button class="secondary-button" (click)="uploadImage(finalPhoto, babySitter.docRef)">Conferma</button>
    </div>
  </div>
</div>


<app-cv-template [babysitter]="babySitter" [generatePages]="generatePages" [currentPage]="currentPage"
  (sendPages)="cvPages = $event" style="position:absolute; z-index: -5; left: -1000px;"></app-cv-template>
