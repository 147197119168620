import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ScheduledAppointment } from '../../models/scheduled-appointment.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ScheduledAppointmentService {
  private baseURL = `${environment.baseURL}/scheduled-appointments`;

  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  getAllScheduledAppointments(): Observable<ScheduledAppointment[]> {
    return this.http.get<ScheduledAppointment[]>(this.baseURL);
  }

  getScheduledAppointmentById(id: number): Observable<ScheduledAppointment> {
    const url = `${this.baseURL}/${id}`;
    return this.http.get<ScheduledAppointment>(url);
  }

  createScheduledAppointment(scheduledAppointment: ScheduledAppointment): Observable<ScheduledAppointment> {
    return this.http.post<ScheduledAppointment>(this.baseURL, scheduledAppointment);
  }

  updateScheduledAppointment(id: number, scheduledAppointment: ScheduledAppointment): Observable<ScheduledAppointment> {
    const url = `${this.baseURL}/${id}`;
    return this.http.put<ScheduledAppointment>(url, scheduledAppointment);
  }

  deleteScheduledAppointment(id: number): Observable<void> {
    const url = `${this.baseURL}/${id}`;
    return this.http.delete<void>(url);
  }

  getScheduledAppointmentsByEntityLinked(entityLinked: String): Observable<ScheduledAppointment[]> {
    const url = `${this.baseURL}/find/${entityLinked}`;
    return this.http.get<ScheduledAppointment[]>(url);
  }

  createAppointmentTask(appointment: ScheduledAppointment, reminderDate: Date) {
    if (appointment.timestamp) {
      return this.firestore.collection('tasks', ref => ref
        .where('entityLinked', '==', appointment.entityLinked)
        .where('type', '==', appointment.type.toString())
      ).get().pipe(switchMap((result) => {
        if (result.docs.length > 0) {
          return this.firestore.collection('tasks').doc(result.docs[0].id).update({
            performAt: reminderDate,
          })
        } else {
          return this.firestore.collection('tasks').add({
            performAt: reminderDate,
            entityLinked: appointment.entityLinked,
            type: appointment.type.toString()
          })
        }
      }));
    } else {
      return of();
    }
  }

  deleteAppointmentTask(appointment: ScheduledAppointment) {
    if (appointment.timestamp) {
      return this.firestore.collection('tasks', ref => ref
        .where('entityLinked', '==', appointment.entityLinked)
        .where('type', '==', appointment.type.toString())
      ).get().pipe(switchMap((result) => {
        if (result.docs.length > 0) {
          return this.firestore.collection('tasks').doc(result.docs[0].id).delete();
        } else {
          return Promise.resolve();
        }
      }));
    } else {
      return of();
    }
  }
}
