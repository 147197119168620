<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title><b>Tracciamento NPS</b></mat-panel-title>
  </mat-expansion-panel-header>
  <div>
    <h3>Tracciamento NPS</h3>
    <div class="mt-4">
      <div class="d-flex">
        <label class="me-2 fw-bold" for="question1">Domanda numero 1:</label>
        <p id="question1"><i>"Su una scala da 0 a 10, con quale probabilità ci consiglierebbe ad amici o colleghi?"</i></p>
      </div>
      <div class="d-flex">
        <div>
          <ngb-rating  (rateChange)="saveNPS($event)" [readonly]="readonly ? true : false" [(rate)]="rating" [max]="11">
            <ng-template let-fill="fill" let-index="index">
              <span
                class="rating-item"
                [class.filled]="fill === 100"
                [class.active]="index + 1 <= rating"
                [class.inactive]="index +1 > rating"
                [style.--rating-color]="index + 1 <= rating ? getRatingColor(index + 1) : false"
              >
                {{ index }}
              </span>
            </ng-template>
          </ngb-rating>
        </div>
        <div class="ms-2 d-flex align-items-center">
          <span class="badge bg-secondary d-flex justify-content-center align-items-center">
            <pre class="mb-0" style="font-size: 1.2rem;">Score: <b>{{ rating -1 }}</b></pre>
          </span>
        </div>
      </div>
      <hr />
    </div>
    <div class="mt-4">
      <div class="d-flex">
        <label class="me-2 fw-bold" for="question1">Domanda numero 2:</label>
        <p id="question1"><i>"Ha qualche consiglio da darci per migliorare il servizio?"</i></p>
      </div>
      <div>
        <form action="" [formGroup]="form" class="">
          <div>
            <label for="Note" class="form-label fw-bold">Note:</label>
            <textarea name="Note" formControlName="notesControl" class="form-control" rows="4" id="Note"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            @if(save){
              <button class="btn btn-secondary mt-2 text-white" (click)="saveNotes()">Salva</button>
            }
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-expansion-panel>
